import { createApp } from 'vue';
import Router from './Router';
import App from './App.vue';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import 'vant/lib/index.css';

(window as any).__VUE_PROD_DEVTOOLS__ = false;
(window as any).__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;

createApp(App)
  .use(Vue3Toastify, {
    autoClose: 3000,
  } as ToastContainerOptions)
  .use(Router)
  .mount('#app');
