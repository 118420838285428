export class UrlHelper {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static parseURL (url: string) : any {
    const protocolEndIndex = url.indexOf(':');
    const protocol = protocolEndIndex !== -1 ? url.slice(0, protocolEndIndex + 1) : '';

    const remainingUrl = protocolEndIndex !== -1 ? url.slice(protocolEndIndex + 3) : url;
    const domainEndIndex = remainingUrl.indexOf('/');
    const domain = domainEndIndex !== -1 ? remainingUrl.slice(0, domainEndIndex) : remainingUrl;

    const remainingPath = domainEndIndex !== -1 ? remainingUrl.slice(domainEndIndex) : '';
    const pathEndIndex = remainingPath.indexOf('?');
    const path = pathEndIndex !== -1 ? remainingPath.slice(0, pathEndIndex) : remainingPath;

    const remainingQuery = pathEndIndex !== -1 ? remainingPath.slice(pathEndIndex) : '';
    const queryEndIndex = remainingQuery.indexOf('#');
    const query = queryEndIndex !== -1 ? remainingQuery.slice(1, queryEndIndex) : remainingQuery.slice(1);

    const hash = queryEndIndex !== -1 ? remainingQuery.slice(queryEndIndex + 1) : '';

    const queryParameters = query
      .split('&')
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .reduce((params:any, param) => {
        const [key, value] = param.split('=');
        params[key] = decodeURIComponent(value);
        return params;
      }, {});

    return {
      protocol,
      domain,
      path,
      queryParameters,
      hash: hash ? `#${hash}` : '',
    };
  }
}
