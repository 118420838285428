<template name="App">
  <div class="app-container">
    <img class="app-title" src="@/Assets/images/em/title.png" alt="众鑫Pro 5G 通信 2.0 线上营业厅" />
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive } from 'vue';
import Router from '@/Router';
import InAppMethodEnums from './Enums/InAppMethodEnums';
import { UrlHelper } from './Utils/UrlHelper';

interface IAppViewState {
  inAppMethod: InAppMethodEnums | undefined;
}

const state = reactive<IAppViewState>({
  inAppMethod: undefined
});

onMounted(() => {
  const urlObj = UrlHelper.parseURL(window.location.href);
  const { userId, agencyId, platformId } = urlObj.queryParameters;

  if (platformId) {
    localStorage.setItem('platformId', platformId as string);
  }

  if (userId) {
    state.inAppMethod = InAppMethodEnums.ProxyApp;
    localStorage.setItem('userId', userId as string);
  } else if (agencyId) {
    state.inAppMethod = InAppMethodEnums.QRScan;
    localStorage.setItem('agencyId', agencyId as string);
  } else if (localStorage.getItem('phone')) {
    state.inAppMethod = InAppMethodEnums.QRScan;
  } else {
    // 进入方式异常，返回
    window.history.back();
  }

  localStorage.setItem('inAppMethod', state.inAppMethod as string);

  if (state.inAppMethod === InAppMethodEnums.QRScan) {
    // 如果是扫码进入，并且没有缓存过手机号，则转到渲染登录页面
    if (!localStorage.getItem('phone')) {
      Router.push({ name: 'Login' });
      return;
    }
  }
  // 跳转到主页
  Router.push({ name: 'Home' });
});
</script>
