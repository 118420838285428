import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../Views/HomeView.vue')
  },
  {
    path: '/purchase-card-step-1',
    name: 'PurchaseCardStep1',
    component: () => import(/* webpackChunkName: "PurchaseCardStep1" */ '../Views/PurchaseCardStep1View.vue')
  },
  {
    path: '/purchase-card-step-2',
    name: 'PurchaseCardStep2',
    component: () => import(/* webpackChunkName: "PurchaseCardStep2" */ '../Views/PurchaseCardStep2View.vue')
  },
  {
    path: '/purchase-card-step-3',
    name: 'PurchaseCardStep3',
    component: () => import(/* webpackChunkName: "PurchaseCardStep3" */ '../Views/PurchaseCardStep3View.vue')
  },
  {
    path: '/purchase-card-step-4',
    name: 'PurchaseCardStep4',
    component: () => import(/* webpackChunkName: "PurchaseCardStep4" */ '../Views/PurchaseCardStep4View.vue')
  },
  {
    path: '/purchase-card-qr-code',
    name: 'PurchaseCardQRCode',
    component: () => import(/* webpackChunkName: "PurchaseCardQRCode" */ '../Views/PurchaseCardQRCodeView.vue')
  },
  {
    path: '/my-order',
    name: 'MyOrder',
    component: () => import(/* webpackChunkName: "MyOrder" */ '../Views/MyOrderListView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../Views/LoginView.vue')
  }
]

const Router = createRouter({
  history: createWebHistory(process.env.VUE_APP_SRC),
  routes
});

Router.beforeEach((to, from, next) => {
  // 对于每个路由跳转，设置不缓存的HTTP头部
  document.head.innerHTML += `<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
                              <meta http-equiv="Pragma" content="no-cache" />
                              <meta http-equiv="Expires" content="0" />`;
  next();
});

export default Router;
